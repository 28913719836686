import { Transition } from '@headlessui/react';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { generateButtonClassNames } from 'ui';
import BaseNewModal from '~/components/NewModal/BaseNewModal';
import NewModalBody from '~/components/NewModal/NewModalBody';
import { SidelineProBadge } from '~/components/SidelinePro/ProBadges';
import ManagedSwipeableCarouselContainer from '~/components/Swipeable/ManagedSwipeableCarouselContainer';
import { SIDELINE_PRO_SLIDES } from '~/constants/imagesAndVideos';
import { NewModalContext } from '~/contexts/NewModalContext';
import {
  PaywallSource,
  fireClickedUpgradeToSidelinePro,
  fireClosedPaywall,
  fireVisitedPaywallModal,
} from '~/services/analytics/events/sidelinePro';

export default function SidelineProPaywallModal({
  onClose,
  open,
  source,
}: {
  onClose: () => void;
  open: boolean;
  source: PaywallSource;
}) {
  useEffect(() => {
    if (open) {
      fireVisitedPaywallModal(source);

      return () => {
        fireClosedPaywall(source);
      };
    }
  }, [open]);

  return (
    <NewModalContext value={{ open, onClose }}>
      <Transition as="div" show={open} appear>
        <BaseNewModal onClose={onClose} open={open} variant="paywall" size="xl">
          <NewModalBody variant="paywall">
            <PaywallModalBody />
          </NewModalBody>

          <hr className="bg-slate-green-600" />
          <div className="bg-turf-green-800 p-4">
            <div className="flex justify-center">
              <Link
                className={generateButtonClassNames({
                  className: 'px-8',
                  size: 'large',
                  color: 'grey',
                })}
                href="/settings/sideline-pro"
                color="grey"
                onClick={() => fireClickedUpgradeToSidelinePro(source)}
              >
                Upgrade to Sideline Pro
              </Link>
            </div>
          </div>
        </BaseNewModal>
      </Transition>
    </NewModalContext>
  );
}

function PaywallModalBody() {
  // Negative margin so the carousel buttons are on the edge of the modal
  return (
    <div className="-mx-6">
      <ManagedSwipeableCarouselContainer
        showIndicators
        indicatorColor="white"
        showNextPrevButtons
      >
        <Slide
          title="Elevate your game with Sideline {PRO}"
          description="Unlock premium features to boost your sales, stand out from the crowd, and streamline your workflow."
          video={SIDELINE_PRO_SLIDES[0]}
        />

        <Slide
          title="Stand out as a {PRO} seller"
          description="Pro badges drive more awareness to your listings, and cover photos and bios establish a stronger brand presence."
          video={SIDELINE_PRO_SLIDES[1]}
        />

        <Slide
          title="{PRO} sellers work smarter, not harder"
          description="Gain access to automated bumps, blocking low ball offers, advanced pricing insights, and better reporting."
          video={SIDELINE_PRO_SLIDES[2]}
        />

        <Slide
          title="Unlock access to {PRO} support"
          description="Access live chat customer support whenever questions arise and team up with a dedicated account manager to help you reach your selling goals."
          video={SIDELINE_PRO_SLIDES[3]}
        />
      </ManagedSwipeableCarouselContainer>
    </div>
  );
}

function Slide({
  description,
  title,
  video,
}: {
  // Include {PRO} to replace with Pro badge
  title: string;
  description: string;
  video: string;
}) {
  let titleContent: React.ReactNode = null;
  const proPosition = title.indexOf('{PRO}');
  if (proPosition > -1) {
    titleContent = (
      <>
        {title.slice(0, proPosition)}
        <SidelineProBadge className="h-10" />
        {title.slice(proPosition + 5)}
      </>
    );
  } else {
    titleContent = title;
  }

  return (
    <div className="text-balance px-6 text-center tracking-wide">
      <video
        src={video}
        autoPlay
        muted
        playsInline
        className="mx-auto mb-4 h-72 w-72"
      />

      <div className="mb-4 text-4xl font-semibold">{titleContent}</div>

      <div className="mb-4 text-xl">{description}</div>
    </div>
  );
}
